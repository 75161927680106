module.exports = {
  Add: 'Add',
  'Are you sure you want to create this':
    'Are you sure you want to create this',
  'Are you sure you want to delete this':
    'Are you sure you want to delete this',
  'Are you sure you want to manage this':
    'Are you sure you want to manage this',
  'IBN Admin Panel': 'IBN Admin Panel',
  Cancel: 'Cancel',
  Confirm: 'Confirm',
  'Contact Us': 'Contact Us',
  ContactUs: 'ContactUs',
  Contacted: 'Contacted',
  'Created At': 'Created At',
  Currency: 'Currency',
  Date: 'Date',
  'Date Published': 'Date Published',
  Day: 'Day',
  Delete: 'Delete',
  Description: 'Description',
  Email: 'Email',
  Error: 'Error',
  Filter: 'Filter',
  Filters: 'Filters',
  Generate: 'Generate',
  'Loading...': 'Loading...',
  'Logged in': 'Logged in',
  Login: 'Login',
  Logs: 'Logs',
  Manage: 'Manage',
  Media: 'Media',
  Message: 'Message',
  'Missing Information': 'Missing Information',
  Name: 'Name',
  No: 'No',
  Option: 'Option',
  Options: 'Options',
  Other: 'Other',
  Password: 'Password',
  'Phone Number': 'Phone Number',
  Priority: 'Priority',
  Reset: 'Reset',
  Saturday: 'Saturday',
  Server: 'Server',
  Status: 'Status',
  Submit: 'Submit',
  Type: 'Type',
  Update: 'Update',
  'Updated At': 'Updated At',
  User: 'User',
  'User Name': 'User Name',
  Users: 'Users',
  Verified: 'Verified',
  Yes: 'Yes',
  added: 'added',
  'can not be empty': 'can not be empty',
  'could not be': 'could not be',
  deleted: 'deleted',
  edited: 'edited',
  generated: 'generated',
  'is invalid': 'is invalid',
  'is required': 'is required',
  'password must be of minimum length 8 and contain at least 1 letter and 1 number':
    'password must be of minimum length 8 and contain at least 1 letter and 1 number',
  successfully: 'successfully',
  'Export CSV': 'Export CSV',
  'Export PDF': 'Export PDF',
  City: 'City',
  Address: 'Address',
  Code: 'Code',
  'Discount Percent': 'Discount Percent',
  Blacklisted: 'Blacklisted',
  'Validity Days': 'Validity Days',
  'Expiry Date': 'Expiry Date',
  'Expires At': 'Expires At',
  'Contact Email': 'Contact Email',
  Price: 'Price',
  'On Sale': 'On Sale',
  'Out Of Stock': 'Out Of Stock',
  Size: 'Size',
  Sizes: 'Sizes',
  'Wish List': 'Wish List',
  Item: 'Item',
  'Item Name': 'Item Name',
  Items: 'Items',
  Subtotal: 'Subtotal',
  'Shipping Type': 'Shipping Type',
  'shipping Amount': 'Shipping Amount',
  'Gift Amount': 'Gift Amount',
  Total: 'Total',
  Token: 'Token',
  Role: 'Role',
  Cart: 'Cart',
  Gift: 'Gift',
  'Gift Cards': 'Gift Cards',
  Gifts: 'Gifts',
  Orders: 'Orders',
  Order: 'Order',
  Collection: 'Collection',
  Collections: 'Collections',
  'Collection Name': 'Collection Name',
  'Collection Type': 'Collection Type',
  'Collection For': 'Collection For',
  'Collection Picture': 'Collection Picture',
  Brand: 'Brand',
  Category: 'Category',
  Set: 'Set',
  Material: 'Material',
  Purity: 'Purity',
  Grams: 'Grams',
  Revenue: 'Revenue',
  'Revenue Percent': 'Revenue Percent',
  'Sale Percent': 'Sale Percent',
  Home: 'Home',
  Featured: 'Featured',
  Testimonials: 'Testimonials',
  Testimonial: 'Testimonial',
  Stars: 'Stars',
  Newsletters: 'Newsletters',
  Newsletter: 'Newsletter',
  Design: 'Design',
  Designs: 'Designs',
  Seller: 'Seller',
  Sellers: 'Sellers',
}
