import React from 'react'

const Link = (path) =>
  function (rowData) {
    return rowData[path] ? (
      <a href={rowData[path]} target="_blank" rel="noreferrer">
        Link
      </a>
    ) : (
      ''
    )
  }

export default Link
