import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import stringUtils from '../../_helpers/stringUtils'
import { collectionService } from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Collection = function () {
  const collectionName = 'Collections'
  const singleCollectionName = 'Collection'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    collectionService.get
  )
  const updateMutation = reactQuery.updateMutation(
    collectionService.update,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    collectionService.remove,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  return (
    <Document
      actions={[
        {
          buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const newBody = objectUtils.compare(getQuery.data, args)
            const { collectionPicture } = newBody
            delete newBody.collectionPicture
            const formData = objectUtils.getFormData(newBody)
            if (collectionPicture && collectionPicture.length)
              Object.keys(collectionPicture).forEach((key) =>
                formData.append('collectionPicture', collectionPicture[key])
              )
            if (
              Object.keys(newBody).length ||
              (collectionPicture && collectionPicture.length)
            )
              return updateMutation.mutate(formData)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Update} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'name',
                label: 'Collection Name',
                type: 'text',
                ...(getQuery.data?.type === 'purity' && {
                  type: 'select',
                  options: types.purityTypes.map((purityType) => ({
                    label: stringUtils.camelCase(purityType),
                    value: purityType,
                  })),
                }),
                value: getQuery.data?.name,
              },
              {
                name: 'priority',
                label: 'Priority',
                type: 'number',
                value: getQuery.data?.priority,
              },
              ...(getQuery.data?.type === 'set'
                ? [
                    {
                      name: 'home',
                      label: 'Home',
                      type: 'boolean',
                      value: getQuery.data?.home,
                    },
                    ...(getQuery.data?.home
                      ? [
                          {
                            name: 'description',
                            label: 'Description',
                            type: 'text',
                            value: getQuery.data?.description,
                          },
                          {
                            name: 'collectionPicture',
                            label: 'Collection Picture',
                            type: 'file',
                            accept: 'image/*',
                          },
                        ]
                      : []),
                  ]
                : []),
              {
                name: 'for',
                label: 'Collection For',
                type: 'autocompleteMultiple2',
                options: types.itemTypes,
                value: getQuery.data?.for,
              },
            ],
          },
        },
        {
          buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translate.Delete} ${translate[singleCollectionName]}`,
          },
          confirm: true,
          info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
    />
  )
}
export default Collection
