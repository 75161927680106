import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import translate from '../../../locales/en'

const AutocompleteMultiple2 = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    isValid: () => !(props.required && !value.length),
  }))
  const [value, setValue] = useState(props.value || [])
  useEffect(() => {
    let isValid = true
    if (props.required && !value.length) isValid = false
    props.setIsValids((s) => {
      s[props.index] = isValid
      return s
    })
    if (isValid)
      props.setIsValid(
        props.isValids.reduce((isAllValid, key, i) => {
          if (i === props.index) return isAllValid && isValid
          return isAllValid && key
        }, true)
      )
    else props.setIsValid(false)
    props.setBody((s) => ({ ...s, [props.name]: value }))
  }, [value])
  return (
    <Autocomplete
      multiple
      margin="normal"
      disablePortal
      fullWidth
      freeSolo={props.freeSolo}
      options={props.options || []}
      onChange={(_, newValue) => setValue(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          label={translate[props.label]}
          type="text"
          fullWidth
          InputLabelProps={{ shrink: true }}
          autoFocus={props.index === 0}
          autoComplete="new-password"
          error={props.required && !value.length}
          {...(props.required &&
            !value.length && {
              helperText: `${translate[props.label]} ${
                translate['is required']
              }`,
            })}
        />
      )}
      value={value}
    />
  )
})
export default AutocompleteMultiple2
