import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import translate from '../../../locales/en'

const Select = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    isValid: () => !(props.required && !value.length),
  }))
  const [value, setValue] = useState(props.value || props.options[0].value)
  useEffect(() => {
    let isValid = true
    if (props.required && !value.length) isValid = false
    props.setIsValids((s) => {
      s[props.index] = isValid
      return s
    })
    if (isValid)
      props.setIsValid(
        props.isValids.reduce((isAllValid, key, i) => {
          if (i === props.index) return isAllValid && isValid
          return isAllValid && key
        }, true)
      )
    else props.setIsValid(false)
    props.setBody((s) => ({ ...s, [props.name]: value }))
  }, [value])
  return (
    <TextField
      margin="normal"
      label={translate[props.label]}
      type="text"
      fullWidth
      InputLabelProps={{ shrink: true }}
      autoFocus={props.index === 0}
      select
      value={value}
      onChange={(event) => setValue(event.target.value)}
      required={props.required}
      error={props.required && !value.length}
      {...(props.required &&
        !value.length && {
          helperText: `${translate[props.label]} ${translate['is required']}`,
        })}
    >
      {props.options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
})
export default Select
