import React, { useState } from 'react'

import reactQuery from '../../_helpers/reactQuery'
import { newsletterService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Newsletter = function () {
  const collectionName = 'Newsletters'
  const singleCollectionName = 'Newsletter'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {}
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(
    queryKey,
    queryParams,
    newsletterService.query
  )
  const createMutation = reactQuery.createMutation(
    newsletterService.create,
    singleCollectionName,
    queryKey
  )
  return (
    <Document
      tableActions={[
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => createMutation.mutate(args),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'email',
                label: 'Email',
                type: 'email',
                required: true,
              },
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
    />
  )
}
export default Newsletter
