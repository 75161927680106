import React from 'react'
import { useParams } from 'react-router-dom'

import reactQuery from '../../_helpers/reactQuery'
import { newsletterService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Newsletter = function () {
  const collectionName = 'Newsletters'
  const singleCollectionName = 'Newsletter'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    newsletterService.get
  )
  const deleteMutation = reactQuery.deleteMutation(
    newsletterService.remove,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  return (
    <Document
      actions={[
        {
          buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translate.Delete} ${translate[singleCollectionName]}`,
          },
          confirm: true,
          info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
    />
  )
}
export default Newsletter
