import React, { useState } from 'react'

import reactQuery from '../../_helpers/reactQuery'
import { testimonialService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Testimonial = function () {
  const collectionName = 'Testimonials'
  const singleCollectionName = 'Testimonial'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {}
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(
    queryKey,
    queryParams,
    testimonialService.query
  )
  const createMutation = reactQuery.createMutation(
    testimonialService.create,
    singleCollectionName,
    queryKey
  )
  return (
    <Document
      tableActions={[
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => createMutation.mutate(args),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'name',
                label: 'Name',
                type: 'text',
                required: true,
              },
              {
                name: 'description',
                label: 'Description',
                type: 'text',
                required: true,
              },
              {
                name: 'stars',
                label: 'Stars',
                type: 'number',
                required: true,
              },
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
    />
  )
}
export default Testimonial
