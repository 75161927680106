const collectionTypes = ['category', 'brand', 'material', 'purity', 'set']
const barsCollectionTypes = ['brand', 'material', 'purity']
const coinsCollectionTypes = ['category']
const purityTypes = ['24k', '22k', '21k', '20k', '18k']
const itemTypes = ['bars', 'jewelry', 'coins']
const orderTypes = [
  'pending',
  'delivering',
  'delivered',
  'returning',
  'returned',
  'canceled',
  'failed',
]
const shippingTypes = ['expedited', 'express']
const userTypes = ['admin', 'user']
module.exports = {
  collectionTypes,
  barsCollectionTypes,
  coinsCollectionTypes,
  purityTypes,
  itemTypes,
  orderTypes,
  shippingTypes,
  userTypes,
}
