import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import stringUtils from '../../_helpers/stringUtils'
import { collectionService, itemService } from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Item = function () {
  const collectionName = 'Items'
  const singleCollectionName = 'Item'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    itemService.get
  )
  const updateMutation = reactQuery.updateMutation(
    itemService.update,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    itemService.remove,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  return (
    <Document
      actions={[
        {
          buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            args.name = args.itemName
            delete args.itemName
            const newBody = objectUtils.compare(getQuery.data, args)
            const { media } = newBody
            delete newBody.media
            const formData = objectUtils.getFormData(newBody)
            if (media && media.length)
              Object.keys(media).forEach((key) =>
                formData.append('media', media[key])
              )
            if (Object.keys(newBody).length || (media && media.length))
              return updateMutation.mutate(formData)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Update} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'itemName',
                label: 'Item Name',
                type: 'text',
                value: getQuery.data?.name,
              },
              {
                name: 'type',
                label: 'Type',
                type: 'select',
                options: types.itemTypes.map((itemType) => ({
                  label: stringUtils.camelCase(itemType),
                  value: itemType,
                })),
                value: getQuery.data?.type,
              },
              {
                name: 'description',
                label: 'Description',
                type: 'text',
                value: getQuery.data?.description,
              },
              ...[
                ...(getQuery.data?.type === 'coins'
                  ? types.coinsCollectionTypes
                  : getQuery.data?.type === 'bars'
                  ? types.barsCollectionTypes
                  : types.collectionTypes),
              ].map((type) => ({
                name: type,
                label: stringUtils.camelCase(type),
                type: 'autocomplete',
                queryKey: 'collections',
                getApi: collectionService.query,
                options: { type, for: getQuery.data?.type },
                keys: { name: type },
                value: { name: getQuery.data?.[type] },
              })),
              ...(getQuery.data?.type === 'coins'
                ? [
                    {
                      name: 'price',
                      label: 'Price',
                      type: 'number',
                      value: getQuery.data?.price,
                    },
                  ]
                : [
                    {
                      name: 'grams',
                      label: 'Grams',
                      type: 'number',
                      step: 0.1,
                      value: getQuery.data?.grams,
                    },
                    {
                      name: 'revenuePercent',
                      label: 'Revenue Percent',
                      type: 'boolean',
                      value: getQuery.data?.revenuePercent,
                    },
                    {
                      name: 'revenue',
                      label: 'Revenue',
                      type: 'number',
                      value: getQuery.data?.revenue,
                    },
                  ]),
              {
                name: 'onSale',
                label: 'On Sale',
                type: 'boolean',
                value: getQuery.data?.onSale,
              },
              ...(getQuery.data?.onSale || body.onSale
                ? [
                    {
                      name: 'salePercent',
                      label: 'Sale Percent',
                      type: 'number',
                      value: getQuery.data?.salePercent,
                    },
                  ]
                : []),
              {
                name: 'outOfStock',
                label: 'Out Of Stock',
                type: 'boolean',
                value: getQuery.data?.outOfStock,
              },
              ...(getQuery.data?.type === 'jewelry'
                ? [
                    {
                      name: 'sizes',
                      label: 'Sizes',
                      type: 'autocompleteMultiple2',
                      freeSolo: true,
                      value: getQuery.data?.sizes,
                    },
                  ]
                : getQuery.data?.type === 'bars'
                ? [
                    {
                      name: 'featured',
                      label: 'Featured',
                      type: 'boolean',
                      value: getQuery.data?.featured,
                    },
                    ...(getQuery.data?.featured
                      ? [
                          {
                            name: 'priority',
                            label: 'Priority',
                            type: 'number',
                            value: getQuery.data?.priority,
                          },
                        ]
                      : []),
                  ]
                : []),
              {
                name: 'media',
                label: 'Media',
                type: 'file',
                accept: 'image/*',
              },
            ],
          },
        },
        {
          buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translate.Delete} ${translate[singleCollectionName]}`,
          },
          confirm: true,
          info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
      notToShow={['wishList']}
    />
  )
}
export default Item
