import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import Collections from '../Collections/Collections'
import ContactUss from '../Collections/ContactUss'
import Designs from '../Collections/Designs'
import Gifts from '../Collections/Gifts'
import Items from '../Collections/Items'
import Newsletters from '../Collections/Newsletters'
import Orders from '../Collections/Orders'
import Sellers from '../Collections/Sellers'
import Testimonials from '../Collections/Testimonials'
import Users from '../Collections/Users'
import Collection from '../Documents/Collection'
import ContactUs from '../Documents/ContactUs'
import Design from '../Documents/Design'
import Gift from '../Documents/Gift'
import Item from '../Documents/Item'
import Newsletter from '../Documents/Newsletter'
import Order from '../Documents/Order'
import Profile from '../Documents/Profile'
import Seller from '../Documents/Seller'
import Testimonial from '../Documents/Testimonial'
import User from '../Documents/User'
import RoleBasedGuard from '../Guards/RoleBasedGuard'

const MainRoutes = function () {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/orders" />} />
      <Route path="/collections" element={<Collections />} />
      <Route path="/collections/:id" element={<Collection />} />
      <Route path="/contactus" element={<ContactUss />} />
      <Route path="/contactus/:id" element={<ContactUs />} />
      <Route path="/designs" element={<Designs />} />
      <Route path="/designs/:id" element={<Design />} />
      <Route path="/gifts" element={<Gifts />} />
      <Route path="/gifts/:id" element={<Gift />} />
      <Route path="/items" element={<Items />} />
      <Route path="/items/:id" element={<Item />} />
      <Route path="/newsletters" element={<Newsletters />} />
      <Route path="/newsletters/:id" element={<Newsletter />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/orders/:id" element={<Order />} />
      <Route path="/sellers" element={<Sellers />} />
      <Route path="/sellers/:id" element={<Seller />} />
      <Route path="/testimonials" element={<Testimonials />} />
      <Route path="/testimonials/:id" element={<Testimonial />} />
      <Route path="/profile" element={<Profile />} />
      <Route
        path="/users"
        element={
          <RoleBasedGuard permitedRoles={['admin']}>
            <Users />
          </RoleBasedGuard>
        }
      />
      <Route
        path="/users/:id"
        element={
          <RoleBasedGuard permitedRoles={['admin']}>
            <User />
          </RoleBasedGuard>
        }
      />
      <Route path="*" element={<Orders />} />
    </Routes>
  )
}
export default MainRoutes
