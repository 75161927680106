const Currency = (path, currency) => (rowData) => {
  const currencyFormatter = new Intl.NumberFormat('en-EG', {
    style: 'currency',
    currency: currency || rowData.currency || 'AED',
  })
  return rowData[path]
    ? currencyFormatter.format(rowData[path])
    : currencyFormatter.format(0)
}

export default Currency
