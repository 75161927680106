import TextField from '@mui/material/TextField'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import translate from '../../../locales/en'

const Number = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    isValid: () => !(props.required && !value) && !(value && +value < 0),
  }))
  const [value, setValue] = useState(props.value)
  useEffect(() => setValue(props.value), [props.value])
  useEffect(() => {
    let isValid = true
    if (props.required && !value) isValid = false
    if (value && +value < 0) isValid = false
    props.setIsValids((s) => {
      s[props.index] = isValid
      return s
    })
    if (isValid)
      props.setIsValid(
        props.isValids.reduce((isAllValid, key, i) => {
          if (i === props.index) return isAllValid && isValid
          return isAllValid && key
        }, true)
      )
    else props.setIsValid(false)
    props.setBody((s) => ({ ...s, [props.name]: value || 0 }))
  }, [value])
  return (
    <TextField
      margin="normal"
      label={translate[props.label]}
      type="number"
      fullWidth
      InputLabelProps={{ shrink: true }}
      autoFocus={props.index === 0}
      disabled={props.disabled}
      inputProps={{ min: props.min || 0, max: props.max, step: props.step }}
      value={value}
      onChange={(event) => setValue(event.target.value)}
      required={props.required}
      error={(props.required && !value) || (value && +value < 0)}
      {...((props.required &&
        !value && {
          helperText: `${translate[props.label]} ${translate['is required']}`,
        }) ||
        (value &&
          +value < 0 && {
            helperText: `${translate[props.label]} ${translate['is invalid']}`,
          }))}
    />
  )
})
export default Number
