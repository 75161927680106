import translate from '../locales/en'

const currencyString = (value, currency) => {
  const currencyFormatter = new Intl.NumberFormat('en-EG', {
    style: 'currency',
    currency: currency || 'AED',
  })
  return currencyFormatter.format(value)
}
const camelCase = (name) => {
  const camelcase = name
    .replace(/([A-Z]+)/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .trim()
  return translate[camelcase] || camelcase
}
const IsJsonString = (str) => {
  try {
    return JSON.parse(str)
  } catch (e) {
    return false
  }
}
const boolean = (value) => (value ? translate.Yes : translate.No)
const twoDigit = (number) =>
  number.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })
const dayIndex = (index) =>
  [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ][index]
export default {
  camelCase,
  currencyString,
  IsJsonString,
  boolean,
  twoDigit,
  dayIndex,
}
