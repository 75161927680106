import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import { sellerService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Seller = function () {
  const collectionName = 'Seller'
  const singleCollectionName = 'Seller'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    sellerService.get
  )
  const updateMutation = reactQuery.updateMutation(
    sellerService.update,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    sellerService.remove,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  useEffect(() => {
    if (!getQuery.isLoading && !getQuery.data.contacted)
      updateMutation.mutate({ contacted: true })
  }, [getQuery.isLoading])
  return (
    <Document
      actions={[
        {
          buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const newBody = objectUtils.compare(getQuery.data, args)
            if (Object.keys(newBody).length)
              return updateMutation.mutate(newBody)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Update} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'contacted',
                label: 'Contacted',
                type: 'boolean',
                value: getQuery.data?.contacted,
              },
            ],
          },
        },
        {
          buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translate.Delete} ${translate[singleCollectionName]}`,
          },
          confirm: true,
          info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
    />
  )
}
export default Seller
