import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useDebounce } from 'use-debounce'

import reactQuery from '../../../_helpers/reactQuery'
import translate from '../../../locales/en'

const AutoComplete = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    isValid: () => !(props.required && !Object.keys(value).length),
  }))
  const [value, setValue] = useState(props.value || {})
  useEffect(() => {
    let isValid = true
    if (props.required && !Object.keys(value).length) isValid = false
    props.setIsValids((s) => {
      s[props.index] = isValid
      return s
    })
    if (isValid)
      props.setIsValid(
        props.isValids.reduce((isAllValid, key, i) => {
          if (i === props.index) return isAllValid && isValid
          return isAllValid && key
        }, true)
      )
    else props.setIsValid(false)
    props.setBody((s) => ({ ...s, ...value }))
  }, [value])
  const [searchValue, setSearchValue] = useState()
  const [debouncedSearch] = useDebounce(searchValue, 500)
  const getQuery = reactQuery.getQuery(
    props.queryKey,
    { ...(debouncedSearch && { search: debouncedSearch }), ...props.options },
    props.getApi
  )
  return (
    <Autocomplete
      margin="normal"
      loading={getQuery.isLoading}
      disablePortal
      fullWidth
      options={getQuery.data?.documents || []}
      getOptionLabel={(option) => `${option.name || option[props.name] || ''}`}
      isOptionEqualToValue={(option, v) =>
        (option.name && v.name && option.name === v.name) ||
        (option[props.name] &&
          v[props.name] &&
          option[props.name] === v[props.name]) ||
        (option.name && v[props.name] && option.name === v[props.name]) ||
        (option[props.name] && v.name && option[props.name] === v.name)
      }
      onChange={(_, newValue) =>
        setValue(
          Object.keys(props.keys).reduce((obj, key) => {
            obj[props.keys[key]] = newValue ? newValue[key] : ''
            return obj
          }, {})
        )
      }
      onInputChange={(_, newValue, reason) => {
        if (reason !== 'reset') setSearchValue(newValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          label={translate[props.label]}
          type="text"
          fullWidth
          InputLabelProps={{ shrink: true }}
          autoFocus={props.index === 0}
          autoComplete="new-password"
          required={props.required}
          error={props.required && !Object.keys(value).length}
          {...(props.required &&
            !Object.keys(value).length && {
              helperText: `${translate[props.label]} ${
                translate['is required']
              }`,
            })}
        />
      )}
      value={value}
      disabled={props.disabled}
    />
  )
})
export default AutoComplete
