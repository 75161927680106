import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import stringUtils from '../../_helpers/stringUtils'
import { collectionService, itemService } from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Item = function () {
  const collectionName = 'Items'
  const singleCollectionName = 'Item'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {}
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(queryKey, queryParams, itemService.query)
  const createMutation = reactQuery.createMutation(
    itemService.create,
    singleCollectionName,
    queryKey
  )
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Document
      tableActions={[
        {
          buttonLabel: translate.Filters,
          buttonColor: 'primary',
          onSubmit: (args) => {
            types.collectionTypes.forEach((collectionType) => {
              if (args[collectionType])
                args[collectionType] = args[collectionType]
                  .map((type) => type[collectionType])
                  .join(',')
            })
            setSearchParams({
              ...Object.fromEntries([...searchParams]),
              ...args,
              query: collectionName,
            })
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          withForm: true,
          form: {
            title: `${translate.Filter} ${translate[singleCollectionName]}`,
            inputs: [
              ...types.collectionTypes.map((type) => ({
                name: type,
                label: stringUtils.camelCase(type),
                type: 'autocompleteMultiple',
                queryKey: 'collections',
                getApi: collectionService.query,
                options: { type },
                keys: { name: type },
                value: queryParams[type]?.length
                  ? queryParams[type]
                      .split(',')
                      .reduce((arr, key) => [...arr, { [type]: key }], [])
                  : '',
              })),
              {
                name: 'type',
                label: 'Type',
                type: 'select',
                options: ['', ...types.itemTypes].map((itemType) => ({
                  label: stringUtils.camelCase(itemType),
                  value: itemType,
                })),
                value: queryParams.type,
              },
              {
                name: 'onSale',
                label: 'On Sale',
                type: 'boolean',
                value: queryParams.onSale ? JSON.parse(queryParams.onSale) : '',
              },
              {
                name: 'outOfStock',
                label: 'Out Of Stock',
                type: 'boolean',
                value: queryParams.outOfStock
                  ? JSON.parse(queryParams.outOfStock)
                  : '',
              },
            ],
          },
          withReset: true,
          resetFunction: () => setSearchParams(),
        },
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const { media, itemName } = args
            args.name = itemName
            delete args.itemName
            delete args.media
            const formData = objectUtils.getFormData(args)
            if (media && media.length)
              Object.keys(media).forEach((key) =>
                formData.append('media', media[key])
              )
            return createMutation.mutate(formData)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'itemName',
                label: 'Item Name',
                type: 'text',
                required: true,
              },
              {
                name: 'type',
                label: 'Type',
                type: 'select',
                options: types.itemTypes.map((itemType) => ({
                  label: stringUtils.camelCase(itemType),
                  value: itemType,
                })),
                required: true,
              },
              {
                name: 'description',
                label: 'Description',
                type: 'text',
                required: true,
              },
              ...[
                ...(body.type === 'coins'
                  ? types.coinsCollectionTypes
                  : body.type === 'bars'
                  ? types.barsCollectionTypes
                  : types.collectionTypes),
              ].map((type) => ({
                name: type,
                label: stringUtils.camelCase(type),
                type: 'autocomplete',
                queryKey: 'collections',
                getApi: collectionService.query,
                options: { type, for: body.type },
                keys: { name: type },
                required: type !== 'set',
              })),
              ...(body.type === 'coins'
                ? [
                    {
                      name: 'price',
                      label: 'Price',
                      type: 'number',
                      required: true,
                    },
                  ]
                : [
                    {
                      name: 'grams',
                      label: 'Grams',
                      type: 'number',
                      step: 0.1,
                      required: true,
                    },
                    {
                      name: 'revenuePercent',
                      label: 'Revenue Percent',
                      type: 'boolean',
                    },
                    {
                      name: 'revenue',
                      label: 'Revenue',
                      type: 'number',
                      required: true,
                    },
                  ]),
              {
                name: 'onSale',
                label: 'On Sale',
                type: 'boolean',
              },
              ...(body.onSale
                ? [
                    {
                      name: 'salePercent',
                      label: 'Sale Percent',
                      type: 'number',
                      required: true,
                    },
                  ]
                : []),
              {
                name: 'outOfStock',
                label: 'Out Of Stock',
                type: 'boolean',
              },
              ...(body.type === 'jewelry'
                ? [
                    {
                      name: 'sizes',
                      label: 'Sizes',
                      type: 'autocompleteMultiple2',
                      freeSolo: true,
                      required: true,
                    },
                  ]
                : body.type === 'bars'
                ? [
                    {
                      name: 'featured',
                      label: 'Featured',
                      type: 'boolean',
                    },
                    ...(body.featured
                      ? [
                          {
                            name: 'priority',
                            label: 'Priority',
                            type: 'number',
                            required: true,
                          },
                        ]
                      : []),
                  ]
                : []),
              {
                name: 'media',
                label: 'Media',
                type: 'file',
                accept: 'image/*',
                required: true,
              },
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
      notToShow={['saved']}
    />
  )
}
export default Item
