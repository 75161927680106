import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import reactQuery from '../../_helpers/reactQuery'
import { giftService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Gift = function () {
  const collectionName = 'Gifts'
  const singleCollectionName = 'Gift'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {}
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(queryKey, queryParams, giftService.query)
  const createMutation = reactQuery.createMutation(
    giftService.create,
    singleCollectionName,
    queryKey
  )
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Document
      tableActions={[
        {
          buttonLabel: translate.Filters,
          buttonColor: 'primary',
          onSubmit: (args) =>
            setSearchParams({
              ...Object.fromEntries([...searchParams]),
              ...args,
              query: collectionName,
            }),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          withForm: true,
          form: {
            title: `${translate.Filter} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'blacklisted',
                label: 'Blacklisted',
                type: 'boolean',
                value: queryParams.blacklisted
                  ? JSON.parse(queryParams.blacklisted)
                  : '',
                required: true,
              },
            ],
          },
          withReset: true,
          resetFunction: () => setSearchParams(),
        },
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => createMutation.mutate(args),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'discountPercent',
                label: 'Discount Percent',
                type: 'number',
                required: true,
              },
              {
                name: 'validityDays',
                label: 'Validity Days',
                type: 'number',
                required: true,
              },
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
    />
  )
}
export default Gift
