import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import objectUtils from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import stringUtils from '../../_helpers/stringUtils'
import { collectionService } from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Collection = function () {
  const collectionName = 'Collections'
  const singleCollectionName = 'Collection'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {}
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(
    queryKey,
    queryParams,
    collectionService.query
  )
  const createMutation = reactQuery.createMutation(
    collectionService.create,
    singleCollectionName,
    queryKey
  )
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Document
      tableActions={[
        {
          buttonLabel: translate.Filters,
          buttonColor: 'primary',
          onSubmit: (args) =>
            setSearchParams({
              ...Object.fromEntries([...searchParams]),
              ...args,
              query: collectionName,
            }),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          withForm: true,
          form: {
            title: `${translate.Filter} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'type',
                label: 'Collection Type',
                type: 'select',
                options: ['', ...types.collectionTypes].map(
                  (collectionType) => ({
                    label: stringUtils.camelCase(collectionType),
                    value: collectionType,
                  })
                ),
                value: queryParams.type,
              },
              {
                name: 'for',
                label: 'Collection For',
                type: 'select',
                options: ['', ...types.itemTypes].map((itemType) => ({
                  label: stringUtils.camelCase(itemType),
                  value: itemType,
                })),
                value: queryParams.for,
              },
            ],
          },
          withReset: true,
          resetFunction: () => setSearchParams(),
        },
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const { collectionPicture } = args
            delete args.collectionPicture
            const formData = objectUtils.getFormData(args)
            if (collectionPicture && collectionPicture.length)
              Object.keys(collectionPicture).forEach((key) =>
                formData.append('collectionPicture', collectionPicture[key])
              )
            return createMutation.mutate(formData)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'name',
                label: 'Collection Name',
                type: 'text',
                ...(body.type === 'purity' && {
                  type: 'select',
                  options: types.purityTypes.map((purityType) => ({
                    label: stringUtils.camelCase(purityType),
                    value: purityType,
                  })),
                }),
                required: true,
              },
              {
                name: 'type',
                label: 'Collection Type',
                type: 'select',
                options: types.collectionTypes.map((collectionType) => ({
                  label: stringUtils.camelCase(collectionType),
                  value: collectionType,
                })),
                required: true,
              },
              {
                name: 'for',
                label: 'Collection For',
                type: 'autocompleteMultiple2',
                options: types.itemTypes,
                required: true,
              },
              {
                name: 'priority',
                label: 'Priority',
                type: 'number',
                required: true,
              },
              ...(body.type === 'set'
                ? [
                    {
                      name: 'home',
                      label: 'Home',
                      type: 'boolean',
                    },
                    ...(body.home
                      ? [
                          {
                            name: 'description',
                            label: 'Description',
                            type: 'text',
                            required: true,
                          },
                          {
                            name: 'collectionPicture',
                            label: 'Collection Picture',
                            type: 'file',
                            accept: 'image/*',
                            required: true,
                          },
                        ]
                      : []),
                  ]
                : []),
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
    />
  )
}
export default Collection
